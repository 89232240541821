/* About.css */

.about-section h3 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    font-weight: bold;
    color: #333;
    text-align: left;
}

.about-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 0.5rem;
    text-align: left;
}

.about-section strong {
    color: #000;
}

.lead {
    font-size: 1.15rem;
    line-height: 1.8;
    color: #444;
    text-align: left;
}

.container {
    max-width: 900px;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.text-center {
    text-align: center !important;
}